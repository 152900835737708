import React from 'react';
import './AddCartSuccessModal.css'
import { Alert } from 'react-bootstrap';
import { images } from '../../constants/Images';
import { ICartSuccess } from '../../models/ICartState';
import { i18n } from '../../services';

interface IProps {
  onToggel: () => void
  show: boolean;
  items: ICartSuccess
}
interface IState {
  toggel: boolean
  
}

export default class AddCartSuccessModal extends React.PureComponent<IProps> {
  state = {
    toggel: false
 }

 onToggel = () => {
    const { toggel } = this.state;
    this.setState({ toggel: !toggel })
 }

    render() {
      const { onToggel, show, items } = this.props
      const { toggel } = this.state;
      console.log(items)
      return (
        <>
        <div className="modal right fade tire-details show orderconfirmed-modal" id="center_modal" tabIndex={-1} role="dialog" aria-labelledby="right_modal_sm" aria-modal="true" style={{ paddingRight: '17px', display: show ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="order_modaltop">
              <div className="tire-mark"><img src={images.innerTireMark} alt="" /></div>
              <div className="content_ordermodal">
                <h3>{i18n.t('Order_Confirmed')}</h3>
                <p className="order_number">
                  {items.message}
                  {/* <span>#45R181</span> */}
                </p>
              </div>
              <div className="car_ordermodal">
                <img src={images.carOrderModal} />
              </div>
            </div>
            <div className="order_modalbottom">
              <div className="order_bottomcontent">
                <p>
                  {items.message_1}
                </p>
                  <a href="javascript:void(0);" onClick={onToggel}>{i18n.t('OK')}</a>
                </div>
            </div>
          </div>
        </div>
      </div>
      {
          show && (
            <div className="modal-backdrop show"></div>
         )
       }
      </>
      )
    }
}