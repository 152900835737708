import React from 'react';
import { Container } from '../../../components/Base';
import { withRouter } from 'react-router-dom';
import { AlertModal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { withTranslation } from 'react-i18next';
import { i18n } from '../../../services';


interface IProps {
  history: any
}

interface IState {
	isShowAlertModal: boolean,
  popupMsg:string,
  okMsg:string
}

class TamaraCancel extends React.Component<IProps, IState> {

  state: IState =  {
		isShowAlertModal: true,
    popupMsg:"",
    okMsg:""
	}

  componentDidMount() {
    setTimeout(() => {
      this.setState({popupMsg:i18n.t(`failed_payment_tamara`), okMsg:i18n.t(`OK`)});
    }, 1000);
  }

  toggelAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART)
	}

    render() {
      const { history} = this.props;
      const { isShowAlertModal } = this.state;
      console.log('rammm===>', i18n.t(`Tamara Payment Cancel`));
       
      return (
        <Container>
          <div style={{height:'300px'}}></div>
          <AlertModal
              show={isShowAlertModal}
              handleClose={this.toggelAlertModal}
              title={(`Tamara Payment Cancel`)}
              description={this.state.popupMsg}
              actionType={'remove'}
              handleAction={()=> {}}
              isConfirmation={false}
              closeButtonName={this.state.okMsg}
              handleNavigation={() => history.push(PATH.CART)}
          />
         </Container>
      )
    }
}

export default withRouter<any, any>(withTranslation()(TamaraCancel)) ;