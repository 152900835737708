import React from 'react';
import { Container } from '../../../components/Base';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IRootState, { ISearchState, ICoreState,IUserState } from '../../../models';
import { search as searcActions, cart as cartActions, core as coreActions } from '../../../actions';
import ICartState, { ITamaraConfirmationParam, ICartAddParam } from '../../../models/ICartState';
import { connect } from 'react-redux';
import { HomeLoader, Notification } from '../../../components';
import { ModalRight, AddCartSuccessModal, Modal } from '../../../components';
import { PATH } from '../../../navigation/NavigationPath';
import { AlertModal } from '../../../components';

interface IProps {
  search: ISearchState;
  getTamaraConfirmation: (param: ITamaraConfirmationParam) => void;
  cart: ICartState;
  addToCart: (param: ICartAddParam) => void;
  addToCartSuccess: (param: any) => void;
  history: any;
}

interface IState {
  isModalShow: boolean;
  isShowAlertModal: boolean
  tamaraPaymentMsg:string
}


class TamaraSuccess extends React.Component<IProps, IState> {

  state: IState =  {
    isModalShow : false,
		isShowAlertModal: false,
    tamaraPaymentMsg:""
	}

  componentDidMount() {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let referenceId = url.searchParams.get("referenceId");
    let paymentStatus = url.searchParams.get("paymentStatus");
    let orderId = url.searchParams.get("orderId");
    
    const { getTamaraConfirmation } = this.props;

    if(orderId && paymentStatus && referenceId){
    const orederParam = {
      paymentStatus:paymentStatus ? paymentStatus : "",
      orderId:orderId ? orderId : "",
      referenceId:referenceId ? referenceId : "",
    }
    getTamaraConfirmation(orederParam);
  }

      // console.log('rammji=====>', url_string);
  }

  componentDidUpdate(prevProps: IProps) {
    const { cart, addToCart } = this.props;
     console.log('rammmmmmmmm====>', cart);
    if(prevProps.cart.tamaraConfirmation !== cart.tamaraConfirmation){
      if(cart.tamaraConfirmation.status_code === 200){
        let orderDataParam: string | null = localStorage.getItem('addtocartparam'); // Example of a nullable value
        console.log('aaa====>', orderDataParam);
        let param2 = JSON.parse(orderDataParam!);
        const param = {
          ...param2,
          platform_type: "Web",
          order_number: cart.tamaraConfirmation.order_number,
          reference_number: cart.tamaraConfirmation.reference_number,
        }
        localStorage.removeItem('addtocartparam');
        addToCart(param);

      } else {
        this.setState({tamaraPaymentMsg:cart.tamaraConfirmation.message});
        this.toggelAlertModal();
        // alert(cart.tamaraConfirmation.message);
        // window.location.href = "/";
      }
    }
  }

  toggelAlertModal = () => {
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    
	}

  closeAlertModal = () => {
    const { history} = this.props;
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })
    history.push(PATH.CART);
	}

  onCloseCartSuccess = () => {
    const { addToCartSuccess, history } = this.props;
    addToCartSuccess({})
    history.push(PATH.CART)
  }

    render() {

      const { cart, history } = this.props;
      const { isShowAlertModal, tamaraPaymentMsg } = this.state;
       
      return (
        <>
        {
          cart.cartSuccess != undefined ?
          <AddCartSuccessModal
          // @ts-ignore-end
            items={cart.cartSuccess}
            onToggel={this.onCloseCartSuccess}
            show={!!Object.keys(cart.cartSuccess).length}
          />
          :
          <HomeLoader/>
        }

        <AlertModal
            show={isShowAlertModal}
            handleClose={this.closeAlertModal}
            title={(`Alert Message`)}
            description={tamaraPaymentMsg}
            actionType={'remove'}
            handleAction={()=> {}}
            isConfirmation={false}
            closeButtonName={(`OK`)}
            handleNavigation={() => history.push(PATH.CART)}
         />

        </>

      )
    }
}

const mapStateToProps = (state: IRootState) => ({
  search: state.search,
  core: state.core,
  cart: state.cart,
  isLoggedin: state.auth.isLoggedin,
  user: state.user.profile
});

const mapDispatchToProps = {
  getTamaraConfirmation: cartActions.getTamaraConfirmation,
  addToCart: cartActions.addToCart,
  addToCartSuccess: cartActions.addToCartSuccess,
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)<any>(TamaraSuccess)) )