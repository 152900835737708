import { api } from '..';
import { ICartAddParam, ICartCancelOrder, ICartOrderDetail, ITamaraPreOrderParam, ITamaraCheckoutParam, ITamaraConfirmationParam, ITabbyPaymentParam } from '../../models/ICartState';



async function addToCart(param: ICartAddParam): Promise<any> {
    const apiType = {
        NORMAL: 'addnewejorder',
        BASIC: 'addnewejbasicorder',
        RETAILER: 'addneworder',
    }
    try {
        if(param.homeInstall){
            return api.postApi(`/v2/search/${apiType[param.type]}`, param)
        }else{
            return api.postApi(`/mycarts/${apiType[param.type]}`, param)
        }
        // return api.postApi(`/v2/search/${apiType[param.type]}`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function tamaraPreOrder(param: ITamaraPreOrderParam): Promise<any> {
    try {
            return api.postApi(`/user/pre-order`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function tamaraCheckout(param: ITamaraCheckoutParam): Promise<any> {
    try {
            return api.postApi(`/user/paymentcheckout`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function tamaraConfirmation(param: ITamaraConfirmationParam): Promise<any> {
    try {
            return api.postApi(`/user/tamarasuccess`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function tabbyCheckout(param: any): Promise<any> {
    try {
            return api.postApi(`/user/tabby-checkout`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function tabbyPayment(param: ITabbyPaymentParam): Promise<any> {
    try {
            return api.postApi(`/user/tabby-paymentweb`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getCart(param: {user_id?: string}): Promise<any> {
    try {
        return api.postApi(`/mycarts/orderlist`, param)
    } catch (e) {
        Promise.reject(e)
    }
}



async function getUserInfo(param: {user_id?: string}): Promise<any> {
    try {
        return api.postApi(`/user/userinfo`, param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function cancelOrder(param: ICartCancelOrder): Promise<any> {
    const urlType = {
        'EJ': `/mycarts/cancelejorder`,
        'Retailer': `/mycarts/cancelorder`
    }
    try {
        return api.postApi(urlType[param.type], param)
    } catch (e) {
        Promise.reject(e)
    }
}

async function getOrderDetail(param: ICartOrderDetail): Promise<any> {
    try {
        return api.postApi(`/mycarts/ejorderdetail`, param)
    } catch (e) {
        Promise.reject(e)
    }
}
export {
    addToCart,
    getCart,
    cancelOrder,
    getOrderDetail,
    getUserInfo,
    tamaraPreOrder,
    tamaraCheckout,
    tamaraConfirmation,
    tabbyCheckout,
    tabbyPayment
}