import React, { createRef } from 'react';
import { images } from '../../../../constants/Images';
import { ISearchBrand, ISearchModel, ISearchTireCache } from '../../../../models/ISearchState';
import config from '../../../../constants/config';
import { i18n } from '../../../../services';
import { withTranslation } from 'react-i18next';
import { Filter } from '../../../../utils';

import { Modal, Input, AlertModal } from '../../../../components';
import { validation } from '../../../../services';
import { retry } from 'redux-saga/effects';

interface IProps {
  items: ISearchTireCache
  onClick: (param: { id: number, type: string }) => void;
  onDetailOpen: () => void;
  onReview: (id: number) => void;
  onChooseLocation: (id: number) => void;
  onHomeInstall: (id: number) => void;
}

interface IState {
  homeInstallModal: boolean;
  validation: any;
  isShowAlertModal: boolean,
  popupMsg:string,
  okMsg:string
}

class TireCard extends React.PureComponent<IProps, IState> {
  state = {
    homeInstallModal: false,
    validation: {},
    isShowAlertModal: false,
    popupMsg:"",
    okMsg:i18n.t(`OK`)
  }

  toggelAlertModal = () => {
		const { isShowAlertModal } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal })  
	}
  
  renderButton = (type: string) => {
    const { items, onClick, onDetailOpen, onReview, onChooseLocation, onHomeInstall } = this.props;
    const buttonType: { [key in string] : any } = {
      "EJNormal": { label: i18n.t(`tire.button.full_detail`) , action: onDetailOpen },
      "EJBasic": { label: i18n.t(`tire.button.install_location`) , action: () => {
        (items.home_delivery == 'No' && Number(items.install_center_count) > 0) ?
        onChooseLocation(items.inventory_id)
        : onHomeInstall(items.inventory_id)
      
      } },
      "Retailer": { label: i18n.t(`tire.button.review`), action: () => onReview(items.brand_id) }
    }
    return (
      <a href="javascript:void(0)" className={`btn btn-${type.replace(/\s/g,'') === 'EJNormal' ? 'default' : 'primary'}`} onClick={buttonType[type.replace(/\s/g,'')].action}>{buttonType[type.replace(/\s/g,'')].label} <img src="img/btn-arrow.png" alt=""/></a>
    )
  }

  getInventroyData = (userRequesttotalQty:number, inv_quantity:number, stockInvtotalPlus12:number) =>{
    let data : any;
    if(userRequesttotalQty > inv_quantity){
      data = (<p className="stok-qty"> <img src={images.soldImage} alt=""/> <span className="sold-out">{i18n.t(`sold.out`)} </span></p>);
    } 
    else if(inv_quantity >= userRequesttotalQty && inv_quantity < stockInvtotalPlus12){
      data = (<p className="stok-qty"> <img src={images.lowStockImage} alt=""/> <span className="low-stock">{i18n.t(`low.stock`)}</span></p>);
    } 
    else {
      data = (<p className="stok-qty"> <img src={images.inStockImage} alt=""/> <span className="In-stock" >{i18n.t(`in.stock`)}</span> </p>);
    }
    return data;
  }

  openPromotionPopup = (description : any) => {
    console.log(description);
    const { isShowAlertModal, popupMsg, okMsg } = this.state;
		this.setState({ isShowAlertModal: !isShowAlertModal, popupMsg: description, okMsg : i18n.t(`OK`) });

  }

  hasDescription(obj: any): obj is { description: string } {
    return obj && typeof obj.description === 'string';
  }

  hasDescriptionArabic(obj: any): obj is { description_arabic: string } {
    return obj && typeof obj.description_arabic === 'string';
  }

  getPromotionsData = (items : any) => {
    let promotions_description = "";
    let promotions_description_arabic = "";
    if (this.hasDescription(items.promotions)) {
      promotions_description = items.promotions.description;
    }
    if (this.hasDescriptionArabic(items.promotions)) {
      promotions_description_arabic = items.promotions.description_arabic;
    }

    if(!promotions_description && !promotions_description_arabic){
      if (this.hasDescription(items.promotions_rear)) {
        promotions_description = items.promotions_rear.description;
      }
      if (this.hasDescriptionArabic(items.promotions_rear)) {
        promotions_description_arabic = items.promotions_rear.description_arabic;
      }
    }
    return {promotions_description, promotions_description_arabic};
  }
  
    render() {
      const { items, onClick, onDetailOpen, onReview, onChooseLocation,onHomeInstall } = this.props;
      const { homeInstallModal } = this.state;
 
      // var userRequesttotalQty = Math.floor(items.quantity) + Math.floor(items.quantity_rear);

      var userRequesttotalQty2 = 0;

      if(Math.floor(items.quantity) > Math.floor(items.quantity_rear)){
        userRequesttotalQty2 = Math.floor(items.quantity);
      } else {
        userRequesttotalQty2 = Math.floor(items.quantity_rear);
      }
      var userRequesttotalQty = userRequesttotalQty2;

      var stockInvtotalPlus12 = userRequesttotalQty2 + 12;

      
      const promo_data = this.getPromotionsData(items);
      let promotions_description = promo_data.promotions_description;
      let promotions_description_arabic = promo_data.promotions_description_arabic;

      // console.log('promotions_description===>',promotions_description);
      // console.log('promotions_description_arabic===>',promotions_description_arabic);

      

      return (
        <div className="row">
        <div className="col-lg-12">
          <div className="listing-block tirepage_listing">
           
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image"><img src={`${config.imageBaseUrl}${items.image}`} alt=""/></figure>
                    {(promotions_description || promotions_description_arabic) && (
                    <div className='promotion_cls'><img src={`${config.imageBaseUrl}/gift.png`} height={'20px'} width={'20px'} /> {i18n.t(`includes_gift`)} <a href='javascript:void(0);' onClick={() =>this.openPromotionPopup(Filter.translate(promotions_description, promotions_description_arabic))} ><span className='question_mark_cls'><img src={`${config.imageBaseUrl}/question_mark.png`} height={'20px'} width={'20px'} /></span></a></div>
                    )}
                  </div>        
                  <div className="content-box">
                    <div className="upper-box">                      
                      <div className="listing-heading">
                        <span className="listing-year">{items.year}</span>  

                        {
                        items.runflat == 1 && (
                          <a href="javascript:void(0);" className="runflat runflatlisting">{i18n.t(`common.label.runflat`)}</a>    
                        )
                      }                       

                        <h3><a href="javascript:void(0);">{Filter.translate(items.brand, items.brand_arabic)}
                        {
                          items.offer_discount > 0 && (
                            <span className="pull-right">{i18n.t(`common.label.discount`)} {items.offer_discount}% </span> 
                          )
                        }
                        </a></h3>
                        {
                        items.madein && (
                          <ul className="madein-text"><li><span>{i18n.t(`common.label.madein`)}</span> {items.madein}</li> </ul>
                        )
                      }
                      </div> 
                      
                    </div>


                    <ul className="listing-info">
                      {
                        items.madein && (
                          <li><span>{i18n.t(`common.label.madein`)}</span> {items.madein}</li> 
                        )
                      }
                      <li><span>{i18n.t(`common.label.warranty`)}</span>{Filter.translate(items.dealer_warranty, items.dealer_warranty_arabic)}</li>

                      {
                        items.price_rear ? (
                        <>
                          <li><span>{i18n.t(`common.label.front_price`)}</span> {items.price_front_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.front_qty`)}</span> {items.quantity}</li>
                          <li><span>{i18n.t(`common.label.Rear_price`)}</span> {items.price_rear_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.rear_qty`)}</span> {items.quantity_rear}</li>
                        </>
                      ) : (
                        <>
                          <li><span>{i18n.t(`common.label.tire_price`)}</span> {items.price_front_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.tire_qty`)}</span> {items.quantity}</li>
                        </>
                      )}
                         
                    </ul>

                <div className="text-line-lbl">                        
                    {  
                          items.record_type && items.record_type == 'Retailer' ? <p><span className="low-stock">{i18n.t(`available_now_at`)} : {Filter.translate(items.shop_name, items.shop_name_ar)} </span><span className="listing-year"> {Filter.translate(items.location, items.location_ar)} </span></p> : (  items.warehouse_user_id && items.home_delivery == 'Yes' && items.install_center_delivery == "Yes" ? <p><span className="low-stock">{i18n.t(`Installed_at_home_or_Install_center_near_you`)}</span> <span className="listing-year">{i18n.t(`Installed_at_home_or_Install_center_near_you_delivery_time`)}</span></p> : ( items.warehouse_user_id && items.home_delivery == 'Yes' ? <p><span className="low-stock">{i18n.t(`Installed_at_your_home`)}</span><span className="listing-year">{i18n.t(`Installed_at_your_home_delivery_time`)}</span></p> : ( items.warehouse_user_id && items.install_center_delivery == "Yes"  ? <p><span className="low-stock">{i18n.t(`delivered_to_Install_center_near_you`)}</span> <span className="listing-year">{i18n.t(`delivered_to_Install_center_near_you_delivery_time`)}</span></p> : "" )) ) 
                    }  
                </div>


                    <div className="listing-bottom-box">    
                      <div className="listing-rates">
                        <div className="left-area">
                          <div className="total-price">{i18n.t(`common.label.total_price`)}</div> 
                          {
                            items.offer_discount == 0 && (
                              <span>{items.total_price}<strong> {i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span>
                            )
                          }                          
                        {
                          items.offer_discount > 0 && (
                            <span>
                                <span className="discount_price">{i18n.t(`common.label.was`)} {items.price_front_vat * items.quantity + items.price_rear_vat * items.quantity_rear} <strong>{i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span> {i18n.t(`common.label.now`)} {items.total_price} <strong>{i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small>
                            </span>  
                          )
                        }
                        </div>
                        {
                        
                        userRequesttotalQty > items.inv_quantity ? '' : <div className="right-area">
                        {this.renderButton(items.record_type)}
                      </div>
                        
                        } 
                        

                      </div>
                    </div>
                  </div>
                  
                </div>
                <ul className="warranty_tirepag">
                  {

                        items.dealer_warranty && (
                          <>
                          <li><span>{i18n.t(`common.label.warranty`)}</span>{Filter.translate(items.dealer_warranty, items.dealer_warranty_arabic)}</li>  </>
                        )
                      }
                </ul>
                <ul className="listing-info tire_pagelisting_mob" >
                      
                      
                      {
                        items.price_rear ? (
                        <>
                          <li><span>{i18n.t(`common.label.front_price`)}</span> {items.price_front_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.front_qty`)}</span> {items.quantity}</li>
                          <li><span>{i18n.t(`common.label.Rear_price`)}</span> {items.price_rear_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.rear_qty`)}</span> {items.quantity_rear}</li>
                        </>
                      ) : (
                        <>
                          <li><span>{i18n.t(`common.label.tire_price`)}</span> {items.price_front_vat} {i18n.t(`sar`)} <small>({i18n.t(`common.label.inc_vat`)})</small></li>
                          <li><span>{i18n.t(`common.label.tire_qty`)}</span> {items.quantity}</li>
                        </>
                      )}
                         
                    </ul>
                    <div className="text-line-lbl mobileView mobilecard_view">                        
                    {  
                          items.record_type && items.record_type == 'Retailer' ? <p><span className="low-stock">{i18n.t(`available_now_at`)} : {items.shop_name} </span><span className="listing-year"> {items.location} </span></p> : (  items.warehouse_user_id && items.home_delivery == 'Yes' && items.install_center_delivery == "Yes" ? <p><span className="low-stock">{i18n.t(`Installed_at_home_or_Install_center_near_you`)}</span> <span className="listing-year">{i18n.t(`Installed_at_home_or_Install_center_near_you_delivery_time`)}</span></p> : ( items.warehouse_user_id && items.home_delivery == 'Yes' ? <p><span className="low-stock">{i18n.t(`Installed_at_your_home`)}</span><span className="listing-year">{i18n.t(`Installed_at_your_home_delivery_time`)}</span></p> : ( items.warehouse_user_id && items.install_center_delivery == "Yes"  ? <p><span className="low-stock">{i18n.t(`delivered_to_Install_center_near_you`)}</span> <span className="listing-year">{i18n.t(`delivered_to_Install_center_near_you_delivery_time`)}</span></p> : "" )) ) 
                    }   
                  </div>
                    <div className="listing-bottom-box tirepage_bottombox totalbtn_box" >    
                      <div className="listing-rates">
                        <div className="left-area">
                          <div className="total-price">{i18n.t(`common.label.total_price`)}</div> 
                          {
                            items.offer_discount == 0 && (
                              <span>{items.total_price}<strong> {i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span>
                            )
                          }                          
                        {
                          items.offer_discount > 0 && (
                          <>
                            <span>
                                <span className="discount_price">{i18n.t(`common.label.was`)} {items.price_front_vat * items.quantity + items.price_rear_vat * items.quantity_rear} <strong>{i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span> <span className="desktopnow">{i18n.t(`common.label.now`)} {items.total_price} <strong>{i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span>
                            </span>  
                            <span className="nowprice">{i18n.t(`common.label.now`)} {items.total_price} <strong>{i18n.t(`sar`)}</strong> <small>({i18n.t(`common.label.inc_vat`)})</small></span>

                            </>
                          )
                        }
                        </div>
                        
                        {userRequesttotalQty > items.inv_quantity ? "" : <div className="right-area">
                          {this.renderButton(items.record_type)}
                        </div>}
                        
                      </div>
                    </div>
                   
          </div>
        </div>

        <AlertModal
              show={this.state.isShowAlertModal}
              handleClose={this.toggelAlertModal}
              title={i18n.t(`gift_popup_details`)}
              description={this.state.popupMsg}
              actionType={'remove'}
              handleAction={()=> {}}
              isConfirmation={false}
              closeButtonName={this.state.okMsg}
              // handleNavigation={() => history.push(PATH.CART)}
          />

      </div>
      )
    }
}

export default withTranslation()(TireCard)
