import { IUserLogin } from "../models/IAuthState";
import { ICartAddParam, ICartData, ICartSuccess, ICartCancelOrder, ICartOrderDetail, ICartOrderDetailCache, ITamaraPreOrderParam, ItamaraPreData, ITamaraCheckoutParam, ITamaraCheckoutData, ITamaraConfirmationParam, ITamaraConfirmationData, ITabbyCheckoutParam, ITabbyCheckoutData, ITabbyPaymentParam, ITabbyPaymentData } from "../models/ICartState";


export enum ActionTypes {
    ADD_TO_CART = 'cart/ADD_TO_CART',
    GET_CART = 'cart/GET_CART',
    SET_CART = 'cart/SET_CART',
    ADD_TO_CART_SUCCESS = 'cart/ADD_TO_CART_SUCCESS',
    CART_CANCEL_ORDER = 'cart/CART_CANCEL_ORDER',
    CART_GET_ORDER_ORDER_DETAIL = 'cart/CART_GET_ORDER_ORDER_DETAIL',
    CART_SET_ORDER_ORDER_DETAIL = 'cart/CART_SET_ORDER_ORDER_DETAIL',
    TAMARA_PRE_ORDER = 'cart/TAMARA_PRE_ORDER',
    SET_TAMARA_PRE_ORDER = 'cart/SET_TAMARA_PRE_ORDER',
    GET_TAMARA_CHECKOUT = 'cart/GET_TAMARA_CHECKOUT',
    SET_TAMARA_CHECKOUT = 'cart/SET_TAMARA_CHECKOUT',
    Get_TAMARA_CONFIRMATION = 'cart/Get_TAMARA_CONFIRMATION',
    SET_TAMARA_CONFIRMATION = 'cart/Set_TAMARA_CONFIRMATION',
    GET_TABBY_CHECKOUT = 'cart/GET_TABBY_CHECKOUT',
    SET_TABBY_CHECKOUT = 'cart/SET_TABBY_CHECKOUT',
    GET_TABBY_PAYMENT = 'cart/GET_TABBY_PAYMENT',
    SET_TABBY_PAYMENT = 'cart/SET_TABBY_PAYMENT',
}

export interface IActionCartGetOrderDetail {
    type: ActionTypes.CART_GET_ORDER_ORDER_DETAIL;
    payload: ICartOrderDetail
}

export interface IActionCartSetOrderDetail {
    type: ActionTypes.CART_SET_ORDER_ORDER_DETAIL;
    payload: ICartOrderDetailCache
}

export interface IActionCartCancelOrder {
    type: ActionTypes.CART_CANCEL_ORDER;
    payload: ICartCancelOrder
}

export interface IActionAddCartSuccess {
    type: ActionTypes.ADD_TO_CART_SUCCESS;
    payload: ICartSuccess
}

export interface IActionGetCart {
    type: ActionTypes.GET_CART;
    payload: {user_id?: string};
}

export interface IActionSetCart {
    type: ActionTypes.SET_CART;
    payload: ICartData[];
}

export interface IActionAddToCart {
    type: ActionTypes.ADD_TO_CART;
    payload: ICartAddParam;
}

export interface IActionTamaraPreOrder {
    type: ActionTypes.TAMARA_PRE_ORDER;
    payload: ITamaraPreOrderParam;
}

export interface IActionSetTamaraPreOrder {
    type: ActionTypes.SET_TAMARA_PRE_ORDER;
    payload: ItamaraPreData;
}

export interface IActionGetTamaraCheckout {
    type: ActionTypes.GET_TAMARA_CHECKOUT;
    payload: ITamaraCheckoutParam;
}

export interface IActionSetTamaraCheckout {
    type: ActionTypes.SET_TAMARA_CHECKOUT;
    payload: ITamaraCheckoutData;
}

export interface IActionGetTabbyCheckout {
    type: ActionTypes.GET_TABBY_CHECKOUT;
    payload: ITabbyCheckoutParam;
}

export interface IActionSetTabbyCheckout {
    type: ActionTypes.SET_TABBY_CHECKOUT;
    payload: ITabbyCheckoutData;
}

export interface IActionGetTabbyPayment {
    type: ActionTypes.GET_TABBY_PAYMENT;
    payload: ITabbyPaymentParam;
}

export interface IActionSetTabbyPayment {
    type: ActionTypes.SET_TABBY_PAYMENT;
    payload: ITabbyPaymentData;
}

export interface IActionGetTamaraConfirmation {
    type: ActionTypes.Get_TAMARA_CONFIRMATION;
    payload: ITamaraConfirmationParam;
}

export interface IActionSetTamaraConfirmation {
    type: ActionTypes.SET_TAMARA_CONFIRMATION;
    payload: ITamaraConfirmationData;
}

export function getCart (data: {user_id?: string}): IActionGetCart {
    return {
      payload: data,
      type: ActionTypes.GET_CART,
    };
}

export function setCart (data: ICartData[]): IActionSetCart {
    return {
      payload: data,
      type: ActionTypes.SET_CART,
    };
}

export function addToCart (data: ICartAddParam): IActionAddToCart {
    return {
      payload: data,
      type: ActionTypes.ADD_TO_CART,
    };
}

export function tamaraPreOrder (data: ITamaraPreOrderParam): IActionTamaraPreOrder {
    return {
      payload: data,
      type: ActionTypes.TAMARA_PRE_ORDER,
    };
}

export function setTamaraPreOrder (data: ItamaraPreData): IActionSetTamaraPreOrder {
    return {
      payload: data,
      type: ActionTypes.SET_TAMARA_PRE_ORDER,
    };
}

export function getTamaraCheckout (data: ITamaraCheckoutParam): IActionGetTamaraCheckout {
    return {
      payload: data,
      type: ActionTypes.GET_TAMARA_CHECKOUT,
    };
}

export function setTamaraCheckout (data: ITamaraCheckoutData): IActionSetTamaraCheckout {
    return {
      payload: data,
      type: ActionTypes.SET_TAMARA_CHECKOUT,
    };
}

export function getTabbyCheckout (data: ITabbyCheckoutParam): IActionGetTabbyCheckout {
    return {
      payload: data,
      type: ActionTypes.GET_TABBY_CHECKOUT,
    };
}

export function setTabbyCheckout (data: ITabbyCheckoutData): IActionSetTabbyCheckout {
    return {
      payload: data,
      type: ActionTypes.SET_TABBY_CHECKOUT,
    };
}

export function getTabbyPayment (data: ITabbyPaymentParam): IActionGetTabbyPayment {
    return {
      payload: data,
      type: ActionTypes.GET_TABBY_PAYMENT,
    };
}

export function setTabbyPayment (data: ITabbyPaymentData): IActionSetTabbyPayment {
    return {
      payload: data,
      type: ActionTypes.SET_TABBY_PAYMENT,
    };
}

export function getTamaraConfirmation (data: ITamaraConfirmationParam): IActionGetTamaraConfirmation {
    return {
      payload: data,
      type: ActionTypes.Get_TAMARA_CONFIRMATION,
    };
}

export function setTamaraConfirmation (data: ITamaraConfirmationData): IActionSetTamaraConfirmation {
    return {
      payload: data,
      type: ActionTypes.SET_TAMARA_CONFIRMATION,
    };
}

export function addToCartSuccess (data: ICartSuccess): IActionAddCartSuccess {
    return {
      payload: data,
      type: ActionTypes.ADD_TO_CART_SUCCESS,
    };
}

export function cancelOrder (data: ICartCancelOrder): IActionCartCancelOrder {
    return {
      payload: data,
      type: ActionTypes.CART_CANCEL_ORDER,
    };
}

export function getOrderDetail (data: ICartOrderDetail): IActionCartGetOrderDetail {
    return {
      payload: data,
      type: ActionTypes.CART_GET_ORDER_ORDER_DETAIL,
    };
}

export function setOrderDetail (data: ICartOrderDetailCache): IActionCartSetOrderDetail {
    return {
      payload: data,
      type: ActionTypes.CART_SET_ORDER_ORDER_DETAIL,
    };
}
  

export type Action =
IActionAddToCart
| IActionGetCart
| IActionSetCart
| IActionAddCartSuccess
| IActionCartCancelOrder
| IActionCartGetOrderDetail
| IActionCartSetOrderDetail
| IActionTamaraPreOrder
| IActionSetTamaraPreOrder
| IActionGetTamaraCheckout
| IActionSetTamaraCheckout
| IActionGetTamaraConfirmation
| IActionSetTamaraConfirmation
| IActionGetTabbyCheckout
| IActionSetTabbyCheckout
| IActionGetTabbyPayment
| IActionSetTabbyPayment
